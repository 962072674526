import { useEffect } from "react";




function TermsAndConditions () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
                <div className="privacy-policy">
                <h1> Terms and conditions </h1>
          <p>
          As an online teacher offering lessons and courses in chemistry, we collect and store certain user data to provide our educational services effectively.
          The data collected is handled in compliance with applicable privacy laws and regulations, and we take appropriate measures to ensure its confidentiality and security.
          We may collect personal information, such as names, email addresses, and other contact details, from users who sign up for our courses or contact us for inquiries.
          We may collect data on how users interact with our website and educational materials, including access times, pages visited, and other analytics data.

          For users making purchases on our website, we collect payment details necessary to process transactions securely. However, sensitive payment information is not stored on our servers; it is handled securely by our third-party payment processors.
          Facilitating Educational Services:

User data is used to facilitate the delivery of online lessons and courses to our students effectively.
We may use users' contact information to communicate important updates, course announcements, and respond to inquiries.
We analyze usage data to enhance our website's performance, content, and user interface.
We utilize certain third-party services, such as Fawry, VisaCard, and Vodafone Cash, to enhance the user experience and provide secure payment processing. Users are encouraged to review the terms and conditions and privacy policies of these third-party providers.
We use cookies on our website to enhance functionality and collect analytics data. Cookies are small text files stored on users' devices to remember preferences and improve browsing experience. Users can control cookie settings through their web browser.

By using our website and services, users consent to the collection, storage, and use of their data as described in this privacy policy.
Please note that this privacy policy may be updated from time to time to reflect changes in our practices or legal requirements. Users are encouraged to review this policy periodically.
If you have any questions or concerns about our privacy practices, please contact us at [saidsayed162@gmail.com].
Conclusion
Thank you for taking the time to review our terms and conditions. We are committed to providing a secure and valuable educational experience to our users. By using our services, you agree to abide by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our website and services.

          </p>
                </div>
        </>
    )
}
export default TermsAndConditions;

