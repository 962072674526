import { useEffect } from "react";





function PrivacyPolicy () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
                <div className="privacy-policy">
                <h1> PrivacyPolicy </h1>
              <p>
              As an online teacher offering lessons and courses to students, we collect and store certain user data to provide our educational services. This data is handled in accordance with applicable privacy laws and regulations. We take appropriate measures to safeguard this information and ensure its confidentiality.

When users access our website or interact with our educational content, we may collect the following types of information:

We may collect personal information such as names, email addresses, and other contact details from users who sign up for our courses or contact us for inquiries.

We may collect information on how users interact with our website and educational materials, including access times, pages visited, and other analytics data.

For users making purchases on our website, we collect payment details necessary to process transactions securely. However, we do not store sensitive payment information on our servers; it is handled securely by our third-party payment processors.

We use the collected data for the following purposes:

User data is used to facilitate the delivery of online lessons and courses to our students.

 We may use users' contact information to communicate important updates, course announcements, and respond to inquiries.

 We analyze usage data to improve our website's performance, content, and user interface.

We utilize certain third-party services to enhance the user experience and provide secure payment processing. These services include Fawry, VisaCard, and Vodafone Cash. Please note that these third-party providers may have their own privacy policies, and users are encouraged to review their terms and conditions.

Additionally, we use cookies on our website to enhance functionality and collect analytics data. Cookies are small text files stored on users' devices to remember preferences and improve browsing experience. Users can control cookie settings through their web browser.

By using our website and services, users consent to the collection, storage, and use of their data as described in this privacy policy.

Please note that this privacy policy may be updated from time to time to reflect changes in our practices or legal requirements. Users are encouraged to review this policy periodically. If you have any questions or concerns about our privacy practices, please contact us at [saidsayed162@gmail.com].
              </p>
                </div>
        </>
    )
}
export default PrivacyPolicy;