import { useEffect } from "react";








function RefundPolicy () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
                <div className="privacy-policy">
                <h1> Refund Policy </h1>
              <p>
              Thank you for choosing our online educational platform. We strive to provide you with high-quality educational content and services. Before you proceed with your purchase, we encourage you to read and understand our refund policy.

1. General Refund Policy

1.1. We offer a 30-day money-back guarantee for courses purchased on our website. If you are not satisfied with the course within 30 days of the purchase date, you are eligible for a full refund, subject to the conditions outlined in this policy.

1.2. To initiate a refund request, you must send an email to [saidsayed162@gmail.com] within the 30-day refund period, stating your name, email address, and the course you wish to refund.

1.3. Refunds will be processed using the original payment method used for the purchase. Please allow a reasonable processing time for the refund to appear in your account.

2. Eligibility for Refund

2.1. Refunds are only applicable to courses purchased directly from our website. If you purchased a course through a third-party platform or distributor, you must refer to their refund policy.

2.2. To be eligible for a refund, you must meet the following criteria:

Your refund request must be made within 30 days from the date of purchase.
You have not completed more than 30% of the course content.
You have not violated our terms and conditions, including our code of conduct.
3. Non-Refundable Items

3.1. Certain items are non-refundable. These include:

Courses that were purchased outside the 30-day refund window.
Courses where more than 30% of the content has been completed.
Any products, services, or materials that are clearly stated as non-refundable during the purchase process.
4. Discretionary Refunds

4.1. In certain exceptional cases, we may offer discretionary refunds outside the standard refund policy. These cases are considered on a case-by-case basis and are subject to our sole discretion.

5. Course Modifications

5.1. We reserve the right to make modifications, updates, or improvements to our courses at any time. Such changes do not entitle users to a refund based on previously available content.

6. Contact Information

6.1. If you have any questions or need assistance with the refund process, please contact us at [saidsayed162@gmail.com].

Conclusion

We value your satisfaction and aim to deliver an enriching learning experience. If, for any reason, you find yourself unsatisfied with our course, you have the option to request a refund within 30 days of purchase. We strive to process refund requests promptly and efficiently. Thank you for choosing our online educational platform, and we hope you enjoy your learning journey with us.

              </p>
                </div>
        </>
    )
}
export default RefundPolicy;